import React from 'react'
import { Head, Grid } from '@components'
import * as st from '@assets/styl/PrivacyPolicy.module.styl'

export default () => <>
  <Head path="/politica-de-privacidade/" />
  <Grid className={st.core}>
    <h1>Política de Privacidade</h1>
    <p>
    Objetivo<br />
    As Empresas Dimas estão engajadas na conscientização dos usuários de seu site em relação aos dados pessoais eventualmente tratados.<br />
    Comprometido em respeitar a sua privacidade e prezar pela transparência, elaboramos a presente política de privacidade, com o objetivo de proporcionar um claro entendimento sobre como é realizado o tratamento dos seus dados pessoais em nosso site. <br />
    Destaca-se que o presente instrumento informa sobre como é realizado o tratamento de dados pessoais coletados exclusivamente no site da We Charge. As demais hipóteses de tratamento de dados de colaboradores, fornecedores, candidatos a vagas de emprego, dentre outros, estão na Política de Privacidade interna das Empresas Dimas, a qual pode ser solicitada pelos titulares através do e-mail privacidade@dimas.com.br. <br />
    Os dados pessoais serão tratados apenas enquanto forem necessários e adequados para as finalidades que embasam a coleta, bem como o tratamento sempre irá se enquadrar à uma das bases legais previstas pela Lei Geral de Proteção de Dados.<br />
    As Empresas Dimas requerem a todos os usuários de seu site que façam a leitura atenta deste documento antes de submeter seus dados nos formulários de cadastro. Seu cadastro significa que você compreendeu como coletamos e tratamos seus dados pessoais. <br />
    Para mais informações acerca do tratamento de seus dados pessoais, entre em contato através do e-mail privacidade@dimas.com.br.<br />
    <br />
    Definições<br />
    <span className="bullet">●</span> Titular: pessoa natural a quem se referem os dados pessoais que são objeto de tratamento;<br />
    <span className="bullet">●</span> Dado pessoal: informação relacionada a pessoa natural identificada ou identificável, como por exemplo nome, e-mail, telefone, dentre outros;<br />
    <span className="bullet">●</span> Dado pessoal sensível: dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, Filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;<br />
    <span className="bullet">●</span> Tratamento: toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração;<br />
    <span className="bullet">●</span> Controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais;<br />
    <span className="bullet">●</span> Encarregado: pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD); <br />
    <span className="bullet">●</span> Operador:  pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador;<br />
    <span className="bullet">●</span> Transferência internacional de dados:  transferência de dados pessoais para país estrangeiro ou organismo internacional do qual o país seja membro;<br />
    <span className="bullet">●</span> Autoridade Nacional de Proteção de Dados (ANPD):  órgão da administração pública federal, integrante da Presidência da República, que possui, dentre outras funções previstas pela Lei n. 13.709/2018, a de zelar pela proteção dos dados pessoais,  elaborar diretrizes para a Política Nacional de Proteção de Dados Pessoais e da Privacidade,  fiscalizar e aplicar sanções em caso de tratamento de dados realizado em descumprimento à legislação, mediante processo administrativo que assegure o contraditório, a ampla defesa e o direito de recurso e promover na população o conhecimento das normas e das políticas públicas sobre proteção de dados pessoais e das medidas de segurança.<br />
    <span className="bullet">●</span> Uso compartilhado de dados: comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados.<br />
    <br />
    Princípios aplicáveis ao tratamento de dados realizado pelas Empresas Dimas<br />
    As Empresas Dimas, no papel de Controlador, asseguram o integral cumprimento do disposto pelo art. 6º, da Lei Geral de Proteção de Dados, o qual estipula os princípios que regem as operações de tratamento de dados pessoais, quais sejam:<br />
    <span className="bullet">●</span> Finalidade: realização do tratamento para propósitos legítimos, específicos, explícitos e informados ao titular, sem possibilidade de tratamento posterior de forma Incompatível com essas finalidades; <br />
    <span className="bullet">●</span> Adequação: compatibilidade do tratamento com as finalidades informadas ao titular, de acordo com o contexto do tratamento; <br />
    <span className="bullet">●</span> Necessidade: limitação do tratamento ao mínimo necessário para a realização de suas finalidades, com abrangência dos dados pertinentes, proporcionais e não excessivos em elação às finalidades do tratamento de dados; <br />
    <span className="bullet">●</span> Livre acesso: garantia, aos titulares, de consulta facilitada e gratuita sobre a forma e a duração do tratamento, bem como sobre a integralidade de seus dados pessoais;<br />
    <span className="bullet">●</span> Qualidade dos dados: garantia, aos titulares, de exatidão, clareza, relevância e atualização dos dados, de acordo com a necessidade e para o cumprimento da finalidade de seu tratamento; <br />
    <span className="bullet">●</span> Transparência: garantia, aos titulares, de informações claras, precisas e facilmente acessíveis sobre a realização do tratamento e os respectivos agentes de tratamento, observados os segredos comercial e industrial;<br />
    <span className="bullet">●</span> Segurança: utilização de medidas técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão; <br />
    <span className="bullet">●</span> Prevenção: adoção de medidas para prevenir a ocorrência de danos em virtude do tratamento de dados pessoais; <br />
    <span className="bullet">●</span> Não discriminação: impossibilidade de realização do tratamento para fins discriminatórios ilícitos ou abusivos; <br />
    responsabilização e prestação de contas: demonstração, pelo agente, da adoção de medidas eficazes e capazes de comprovar a observância e o cumprimento das normas de proteção de dados pessoais e, inclusive, da eficácia dessas medidas.<br />
    <br />
    Hipóteses em que é permitido o tratamento de dados<br />
    A LGPD prevê, em seu art. 7º, as hipóteses em que o tratamento de dados pessoais é permitido. Estas hipóteses são denominadas “bases legais” e elas podem ser utilizadas tanto individualmente, quanto de maneira conjunta, para respaldar o tratamento de dados. Importante esclarecer que as bases legais para o tratamento de dados são:<br />
    <span className="bullet">●</span> O consentimento; <br />
    <span className="bullet">●</span> O cumprimento de obrigação legal ou regulatória pelo controlador;<br />
    <span className="bullet">●</span> O tratamento e uso compartilhado de dados realizado pela administração pública necessários à execução de políticas públicas previstas em leis e regulamentos ou respaldadas em contratos, convênios ou instrumentos congêneres;<br />
    <span className="bullet">●</span> A realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais; <br />
    <span className="bullet">●</span> A execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados; <br />
    <span className="bullet">●</span> O exercício regular de direitos em processo judicial, administrativo ou arbitral;<br />
    <span className="bullet">●</span> A proteção da vida ou da incolumidade física do titular ou de terceiro.<br />
    <br />
    Dados coletados, a forma e a finalidade da coleta<br />
    Dados pessoais <br />
    As Empresas Dimas realizarão o tratamento dos dados inseridos no site https://wecharge.com.br/. Estes dados serão fornecidos diretamente por você através dos formulários “Assine nossa newsletter e mantenha-se atualizado”, e, “Contato > Fale com a gente”:<br />
    <span className="bullet">●</span> Nome;<br />
    <span className="bullet">●</span> E-mail; <br />
    <span className="bullet">●</span> Telefone;<br />
    Estes dados serão coletados para cumprir as seguintes finalidades: <br />
    <span className="bullet">●</span> Contatar o usuário do site por e-mail e/ou WhatsApp;<br />
    <span className="bullet">●</span> Efetuar o cadastro do usuário para recebimento da newsletter.<br />
    <br />
    Dados pessoais <br />
    Os cookies são, em síntese, um pequeno arquivo de texto que possui uma etiqueta de identificação exclusiva, colocada no seu computador por um site. Por meio desse arquivo, podem ser armazenadas diversas informações, desde as páginas visitadas até os dados fornecidos voluntariamente ao site.<br />
    <br />
    As Empresas Dimas informam que também irão tratar dados fornecidos indiretamente pelos usuários do site, por meio de cookies enviados ao seu navegador, que não possibilitam a identificação do usuário e são necessários para algumas finalidades, quais sejam:<br />
    <br />
    <div>
      <table>
        <thead>
            <tr>
              <th>
                Cookie
              </th>
              <th>
                Finalidade
              </th>
              <th>
                Tempo de Permanência
              </th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              VisitorId
            </td>
            <td>
              Preserva os estados dos usuários nas solicitações de página.
            </td>
            <td>
              2 meses
            </td>
          </tr>
          <tr>
            <td>
              @@scroll#
            </td>
            <td>
              Não classificado.
            </td>
            <td>
              Sessão
            </td>
          </tr>
          <tr>
            <td>
              _ga
            </td>
            <td>
              Cadastra um ID único que é utilizado para gerar dados estatísticos sobre como o visitante utiliza o site.
            </td>
            <td>
              2 anos
            </td>
          </tr>
          <tr>
            <td>
              _gat
            </td>
            <td>
              Usado pelo Google Analytics para reduzir a taxa de solicitação.
            </td>
            <td>
              1 dia
            </td>
          </tr>
          <tr>
            <td>
              _gd
            </td>
            <td>
              Não classificado.
            </td>
            <td>
              Sessão
            </td>
          </tr>
          <tr>
            <td>
              _gid
            </td>
            <td>
              Cadastra um ID único que é utilizado para gerar dados estatísticos sobre como o visitante utiliza o site.
            </td>
            <td>
              1 dia
            </td>
          </tr>
          <tr>
            <td>
              _fbp
            </td>
            <td>
              Usado pelo Facebook para entregar uma série de produtos publicitários, como lances em tempo real de terceiros anunciantes.
            </td>
            <td>
              3 meses
            </td>
          </tr>
          <tr>
            <td>
              fr
            </td>
            <td>
              Usado pelo Facebook para entregar uma série de produtos publicitários, como lances em tempo real de terceiros anunciantes.
            </td>
            <td>
              3 meses
            </td>
          </tr>
          <tr>
            <td>
              tr
            </td>
            <td>
              Usado pelo Facebook para entregar uma série de produtos publicitários, como lances em tempo real de terceiros anunciantes.
            </td>
            <td>
              Sessão
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    Período de armazenamento dos dados pessoais<br />
    Os dados coletados no site da We Charge serão armazenados: a) pelo tempo exigido por lei; b) até o término do tratamento dos dados pessoais, ou seja, quando do alcance da finalidade ou quando os dados deixarem de ser necessários ou pertinentes ao alcance da finalidade ou quando o titular estiver em seu direito de solicitar o término do tratamento e a exclusão de seus dados ou quando houver determinação legal nesse sentido; c) pelo tempo necessário a preservar o legítimo interesse das Empresas Dimas, conforme o caso; d) pelo tempo necessário para resguardar o exercício regular de direitos das Empresas Dimas.<br />
    Controlador dos dados pessoais<br />
    As Empresas Dimas serão o controlador, de seus dados pessoais. Para a finalidade da legislação aplicável, controlador é a quem compete as decisões relativas ao tratamento de dados pessoais.<br />
    Encarregado dos dados pessoais<br />
    As Empresas Dimas nomeiam como Encarregado o Comitê de Segurança e Privacidade das Empresas Dimas que poderão ser contactados através do endereço de e-mail privacidade@dimas.com.br. <br />
    <br />
    Compartilhamento dos seus dados pessoais<br />
    As Empresas Dimas compartilham com a agência TikiWeb, RD Station e Neurologic para as seguintes finalidades:<br />
    <span className="bullet">●</span> Desenvolvimento e codificação do site;<br />
    <span className="bullet">●</span> Adoção de eventuais providências essenciais ao bom funcionamento das funcionalidades oferecidas no site;<br />
    <span className="bullet">●</span> Disponibilizar a ferramenta de ChatBot para o envio de proposta de financiamento de veículo; <br />
    <span className="bullet">●</span> Gerenciar os dados dos clientes interessados em nossos serviços e produtos para, eventualmente, contatá-los.<br />
    <span className="bullet">●</span> Ademais, no caso de candidatos a oportunidades de emprego, poderemos compartilhar seus dados com a plataforma Gupy, para a seleção de candidatos e realização de processo seletivo. <br />
    <br />
    Direitos em relação aos seus dados pessoais<br />
    Você possui diversos direitos em relação aos seus dados pessoais, os quais as Empresas Dimas se comprometem em assegurar, dentre eles: <br />
    <span className="bullet">●</span> A confirmação da existência de tratamento; <br />
    <span className="bullet">●</span> O acesso aos dados; <br />
    <span className="bullet">●</span> A correção de dados incompletos, inexatos ou desatualizados; <br />
    <span className="bullet">●</span> A anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade; <br />
    <span className="bullet">●</span> A portabilidade a outro fornecedor de serviço ou produto, mediante requisição expressa; <br />
    <span className="bullet">●</span> A informação das entidades públicas e privadas com as quais o controlador realizou o uso compartilhado de dados. 
    Armazenamento e segurança <br />
    As Empresas Dimas estão engajadas com a segurança dos seus dados pessoais. O Controlador adota todas as precauções para manter essa proteção, empregando sistemas de segurança e procedimentos adotados pelo mercado para proteger os seus dados.<br />
    Cumpre ressaltar, ainda, que as Empresas Dimas comunicarão à autoridade nacional e ao titular a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante aos titulares.<br />
    <br />
    Dúvidas | Contato<br />
    Caso você entenda que seus dados foram utilizados de maneira incompatível com esta Política ou com as suas escolhas enquanto titular, ou, ainda, se você tiver outras dúvidas, comentários ou sugestões, entre em contato conosco através do endereço de e-mail privacidade@dimas.com.br. <br />
    <br />
    Alterações<br />
    As Empresas Dimas reservam o direito de alterar esta Política de Privacidade a qualquer momento, mediante publicação da versão atualizada nesta página, a fim de garantir que esta reflita o real tratamento de dados pessoais realizado por nós.<br />
    A presente Política de Privacidade entra em vigor em 22 de setembro de 2021.<br />   
    </p>
  </Grid>
</>